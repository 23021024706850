<template>
	<div class="view view-news" :class="$route.name">
		<!-- <div class="background" :data-category="logged_user.role.category"></div> -->

		<news v-if="$route.name == 'news'" />

		<div v-if="$route.name == 'news-post'" class="content">
			<div class="scroll" v-scrollbar>
				<div>
					<news-post />
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import News from '@/components/game/news/news'
import NewsPost from '@/components/game/news/news-post'

export default {
	name: 'view-news',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'logged_user' ]),
	},
	components: { News, NewsPost }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile, #app.tablet-vertical, #app.tablet-horizontal
	.view-news
		.side, .content
			max-width none
		.side
			margin 0
			height 100px
			width 100%
		// .content
		// 	float none
		// 	position absolute
		// 	left 0
		// 	top 0
		// 	right 0
		// 	height calc(100% - 100px)
		.background
			display none

#app.mobile
	.view-news
		.content
			.close
				display block

.view-news
	display block
	.content
		height 100%
	
	
</style>
