<template>
	<div class="news">
		<div class="news-content">
			<div class="head">
				<main-title text="News" icon="menu/menu-news" />
				<ui-button v-if="current_journal" :to="{ name: 'news-post' }" color="green" class="write-button">Publish a news article or statement</ui-button>
			</div>
			<div class="content" v-scrollbar>
				<div>
					<div class="write-buttons">
						<ui-button v-if="current_journal" :to="{ name: 'news-post' }" color="green" class="write-button">Publish a news article or statement</ui-button>
					</div>
					
					<div class="news-stories">
						<news-story v-for="story in chirp.stories" v-show="visible_papers.find((p) => story.tags.includes(p))" :story="story" :key="story.id" />
					</div>
					
				</div>
			</div>
		</div>
		<!-- <div v-if="app.device !== 'mobile' && app.device !== 'tablet-vertical'" class="news-side">
			<div class="head">
				<main-title text="Currently viewing" :closable="false" />
			</div>
			<div class="content">

				<div class="papers">
					<div v-for="paper in active_papers" class="paper" :class="{ hidden: !paper.visible }" :key="paper.id" v-hammer:tap="() => paper.visible = !paper.visible">
						<ui-icon :name="'news/' + paper.slug" :size="80" />
						<div class="eye">
							<ui-icon v-show="paper.visible" :name="'news/eye'" :size="32" />
							<ui-icon v-show="!paper.visible" :name="'news/eye-off'" :size="32" />
						</div>
					</div>
				</div>
				
			</div>
		</div> -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainTitle from '@/components/game/main-title'
import NewsStory from '@/components/game/news/news-story'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'news',
	data() {
		return {
			papers: []
		}
	},
	computed: {
		...mapGetters([ 'app', 'chirp', 'logged_user', 'current_journal' ]),
		active_papers() {
			if (this.papers) {
				return this.papers.filter((paper) => { return this.chirp.stories.filter((m) => { return m.tags.includes(paper.slug) }).length > 0 })
			}
			return []
		},
		visible_papers() {
			return this.papers.filter((p) => { return p.visible }).map((p) => { return p.slug })
		},
		new_stories() {
			let n = { 'the-buzz': 0, 'capitol-news': 0, 'world-news-network': 0, 'usa-news': 0, 'tri-force-news': 0, 'star-news': 0, 'nola-post': 0, 'the-white-house': 0 }

			this.papers.forEach((paper) => {
				n[paper.slug] = this.chirp.stories.filter((m) => {
					return m.tags.includes(paper.slug) && !m.read_by.includes(this.logged_user.id) && m.sender.id != this.logged_user.id
				}).length
			})

			return n
		}
	},
	mounted() {
		this.chirp.news_papers.forEach((p) => {
			this.papers.push({ ...p, visible: true })
		})
	},
	components: { UiButton, MainTitle, NewsStory }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

#app.tablet-horizontal
	.news
		.news-content
			width 100%
			> .head
				>>> .ui-link
					transform translateY(8px)
			
#app.mobile, #app.tablet-vertical
	.news
		.news-content
			width 100%
			padding 0 
			> .head
				padding-left 24px
				padding-right 24px
				.write-button
					display none
			> .content
				.write-buttons
					display block


.news
	flex flex-start flex-start
	height 100%
	.news-content
		width calc(100% - 420px)
		padding 0 32px
		height 100%
		// background-color blue_dark
		> .head
			height 80px
			padding 16px 0 0 0
			>>> .main-title
				margin 0
				padding 0
				height 64px
				flex flex-start center
				border-bottom 2px solid var(--color-primary)
			>>> .write-button
				position absolute
				right 48px
				top calc(50% - 14px)
				.button
					height 40px
					text-transform uppercase
					font-weight 700
					color blue_dark
		> .content
			height calc(100% - 80px)
			.write-buttons
				display none
				padding 32px 16px 0 16px
				text-align center
				>>> .ui-button .button
					white-space normal
					height auto
					padding 16px 32px 12px 32px
					min-height 48px
			.news-stories
				padding 32px 0 0 0
	.news-side
		width 420px
		padding 0 32px
		height 100%
		background-color blue_darker
		>>> .main-title
			padding-bottom 8px
			border-bottom 2px solid var(--color-primary)
			text-align right
		.papers
			height 100%
			.paper
				text-align right
				padding-right 64px
				cursor pointer
				&.hidden
					opacity 0.25
				.eye
					position absolute
					right 0
					top calc(50% - 16px)
					width 32px
					height 32px
					&:before
						content ''
						position absolute
						left -16px
						top calc(50% - 2px)
						width 4px
						height 4px
						border-radius 50%
						background-color var(--color-primary)


</style>
