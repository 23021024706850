<template>
	<div class="news-post">
		<form class="news-post-content">
			
			<div class="content">
				<div class="header">
					<ui-icon :name="'news/' + current_journal" :size="150" />
				</div>

				<div class="line">
					<label for="title" class="label">Title (mandatory)</label>
					<input type="text" class="input" id="title" ref="title" v-model="title">
				</div>


				<div class="line">
					<label for="story" class="label">Story (mandatory)</label>
					<textarea class="input" rows="8" id="story" v-model="story"></textarea>
				</div>

				<div v-if="files.images.length > 0" class="line">
					<div class="label">Would you like to add a picture ? (optional)</div>
					<hooper :settings="hooper_settings" ref="slider" @slide="slide">
						<slide v-for="image in files.images" :key="image.id">
							<div class="image" :class="{ active: selected_image && selected_image.id == image.id }" v-bg="image" v-hammer:tap="() => select_image(image)"></div>
						</slide>
					</hooper>
					<div class="arrow prev" :class="{ disabled: current_slide <= 0 }" v-hammer:tap="() => prev()"></div>
					<div class="arrow next" :class="{ disabled: current_slide >= (files.images.length - hooper_settings.itemsToShow) }" v-hammer:tap="() => next()"></div>
					<div class="hint">Click the picture to select it. Click again to deselect.</div>
				</div>
			</div>

			<div class="buttons">
				<ui-button to="/game/news" color="red">Cancel <ui-icon name="cancel" :size="24" /></ui-button>
				<ui-button :inactive="!allow_publish" color="green" v-hammer:tap="() => publish()">Publish <ui-icon name="news/notepad" :size="24" /></ui-button>
			</div>

		</form>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiButton from '@/components/ui/ui-button'
import { Hooper, Slide } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
	name: 'news-post',
	data() {
		return {
			title: '',
			story: '',
			current_slide: null,
			selected_image: null,
			hooper_settings: {
				itemsToShow: 5,
				centerMode: false,
				trimWhiteSpace: true
			}
		}
	},
	computed: {
		...mapGetters([ 'files', 'current_journal' ]),
		allow_publish() {
			return this.title.trim() && this.story.trim()
		}
	},
	watch: {
		title(newValue) {
			localStorage.setItem('ithrive-text-news-title', newValue)
		},
		story(newValue) {
			localStorage.setItem('ithrive-text-news-story', newValue)
		}
	},
	methods: {
		slide(data) {
			this.current_slide = data.currentSlide
		},
		prev() {
			this.$refs.slider.slidePrev()
		},
		next() {
			this.$refs.slider.slideNext()
		},
		select_image(image) {
			if (this.selected_image && this.selected_image.id == image.id)
				this.selected_image = null
			else
				this.selected_image = image
		},
		async publish() {
			if (this.allow_publish) {
				let tags = [ 'all', this.current_journal ]

				let message_id = await this.$store.dispatch('send_message', {
					title: this.title,
					message: this.story,
					channel: '',
					tags,
					message_type: 'Chirp',
					medias: [ (this.selected_image || { url: '' }).url ]
				})
				if (message_id) {
					this.title = ''
					this.story = ''
					this.selected_image = null
					this.$nextTick(() => {
						this.$router.push({ path: '/game/news' })
					})
				}
			}
		}
	},
	mounted() {
		let story = localStorage.getItem('ithrive-text-news-story')
		if (story) this.story = story

		let title = localStorage.getItem('ithrive-text-news-title')
		if (title) this.title = title
		this.$refs.title.focus()
	},
	components: { UiButton, Hooper, Slide }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.news-post
	max-width 600px
	padding-right 16px
	margin 0 auto
	.news-post-content
		padding 16px 0
		.content
			background-color var(--color-news-post-bg)
			padding 32px
			color var(--color-news-post-text)
			box-shadow 0 3px 6px alpha(#000, 30%)
			.header
				height 150px
				border-bottom 2px solid var(--color-primary)
				margin 0 0 32px 0
				.ui-icon
					position absolute
					left 50%
					top 50%
					transform translate(-50%, -50%)
			.line
				margin 0 0 16px 0
				.label
					display block
					text-transform uppercase
					line-height 24px
				.input
					display block
					width 100%
					height 48px
					padding 0 16px
					border none
					background var(--color-input-text-bg)
					border-radius 4px
					font inherit
					color var(--color-input-text-text)
					outline none
					&:focus
						background var(--color-input-text-bg-focus)
				textarea.input
					resize none
					height auto
					padding 16px
					line-height 18px
				.hint
					width 100%
					margin 8px 0 0 0
					text-align center
				
				.hooper
					height (96px + 8px)
					outline none
					.hooper-slide
						.image
							margin 4px auto
							height 96px
							width 96px
							background-size contain
							cursor pointer
							opacity 0.5
							&:hover
								opacity 0.6
							&.active
								box-shadow 0 0 0 4px var(--color-primary)
								opacity 1
				.arrow
					position absolute
					top calc(50% - 16px)
					width 0
					height 0
					border-style solid
					cursor pointer
					&.disabled
						opacity 0.5
						cursor not-allowed
					&.prev
						left -24px
						border-width 16px 16px 16px 0
						border-color transparent var(--color-primary) transparent transparent
					&.next
						right -24px
						border-width 16px 0 16px 16px
						border-color transparent transparent transparent var(--color-primary)

		.buttons
			margin 16px 0 0 0
			flex space-between center
			>>> .ui-button
				.button
					padding-top 4px
					text-transform uppercase
					font-family 'Josefin Sans'
					font-weight 700
					font-size 1.6rem
				.ui-icon
					margin-left 8px
					transform translateY(-2px)

</style>